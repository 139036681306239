export class AppInfoModel {
  constructor ({
    about_app = null,
    column = null,
    operate_company = null,
    contact_us = null,
    faq = null,
    info_change_request = null,
    information = null,
    library = null,
    privacy_policy = null,
    support = null,
    term_of_service = null,
    notification = null,
    line_contact = null,
    line_contact_explain = null,
  } = {}) {
    this.about_app = about_app
    this.column = column
    this.operate_company = operate_company
    this.contact_us = contact_us
    this.faq = faq
    this.info_change_request = info_change_request
    this.information = information
    this.library = library
    this.privacy_policy = privacy_policy
    this.support = support
    this.term_of_service = term_of_service
    this.notification = notification
    this.line_contact = line_contact
    this.line_contact_explain = line_contact_explain
  }
}

/**
 * create a readonly instant of UserModel
 * @param data
 * @returns {Readonly<AppInfoModel>}
 */
export function createAppInfo (data) {
  return Object.freeze(
    new AppInfoModel(data)
  )
}
