var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-menu"},[_c('div',{staticClass:"page-body"},[_c('div',{staticClass:"my-menu__user-name"},[_vm._v(" "+_vm._s(_vm.currentUserName)+" ")]),_c('div',{staticClass:"my-menu__main-nav"},[_c('div',{staticClass:"my-menu__main-nav__notification",class:{'my-menu__main-nav__notification--unread': _vm.notification.unreadCount},on:{"click":_vm.openNotificationList}},[_vm._m(0),_c('div',{staticClass:"my-menu__main-nav__text"},[_vm._v(" "+_vm._s(_vm.$t('nav_notice'))+" ")])]),_c('div',{staticClass:"my-menu__main-nav__column",on:{"click":function($event){return _vm.openColumnPage(_vm.appInfo.column)}}},[_vm._m(1),_c('div',{staticClass:"my-menu__main-nav__text"},[_vm._v(" "+_vm._s(_vm.$t('nav_column'))+" ")])]),_c('router-link',{attrs:{"to":{name: _vm.Page.UserChangeInfo},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('div',{staticClass:"my-menu__main-nav__user-info",on:{"click":navigate}},[_c('div',{staticClass:"my-menu__main-nav__img"},[_c('img',{attrs:{"src":"/images/icons/icon-person.svg","height":"22.31px","alt":"Person icon"}})]),_c('div',{staticClass:"my-menu__main-nav__text"},[_vm._v(" "+_vm._s(_vm.$t('nav_user_info'))+" ")])])]}}])})],1),_c('div',{staticClass:"my-menu__menu"},[_c('div',{staticClass:"collapse",class:{collapsed: !_vm.menuOpenStatus.support}},[_c('div',{staticClass:"collapse__toggle",on:{"click":function($event){return _vm.toggleCollapse('support')}}},[_vm._v(" "+_vm._s(_vm.$t('nav_support'))+" ")]),_c('div',{staticClass:"collapse__content",attrs:{"id":"collapse__content__support"}},[_c('div',{staticClass:"collapse__item",on:{"click":function($event){_vm.openExternalLink(_vm.appInfo.faq, _vm.$t('nav_faq'))}}},[_vm._v(" "+_vm._s(_vm.$t('nav_faq'))+" ")]),_c('div',{staticClass:"collapse__item",on:{"click":function($event){_vm.openExternalLink(_vm.appInfo.contact_us, _vm.$t('nav_contact_us'))}}},[_vm._v(" "+_vm._s(_vm.$t('nav_contact_us'))+" ")]),_c('div',{staticClass:"collapse__item",on:{"click":function($event){_vm.openExternalLink(_vm.appInfo.info_change_request, _vm.$t('nav_info_change_request'))}}},[_vm._v(" "+_vm._s(_vm.$t('nav_info_change_request'))+" ")])])]),_c('div',{staticClass:"collapse",class:{collapsed: !_vm.menuOpenStatus.config}},[_c('div',{staticClass:"collapse__toggle",on:{"click":function($event){return _vm.toggleCollapse('config')}}},[_vm._v(" "+_vm._s(_vm.$t('nav_config'))+" ")]),_c('div',{staticClass:"collapse__content",attrs:{"id":"collapse__content__config"}},[_c('router-link',{attrs:{"to":{name: _vm.Page.ConfigPushNotification},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('div',{staticClass:"collapse__item",on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t('nav_config_push_notification'))+" ")])]}}])}),(_vm.userConfig.canChangeEmail)?_c('div',{staticClass:"collapse__item",on:{"click":_vm.confirmChangeEmail}},[_vm._v(" "+_vm._s(_vm.$t('nav_change_email'))+" ")]):_vm._e(),(_vm.userConfig.canChangePassword)?_c('router-link',{attrs:{"to":{name: _vm.Page.ChangePassword},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('div',{staticClass:"collapse__item",on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t('nav_change_password'))+" ")])]}}],null,false,312576049)}):_vm._e(),_c('router-link',{attrs:{"to":{name: _vm.Page.ConfigSecurity},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('div',{staticClass:"collapse__item",on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t('nav_config_security'))+" ")])]}}])})],1)]),_c('div',{staticClass:"collapse",class:{collapsed: !_vm.menuOpenStatus.information}},[_c('div',{staticClass:"collapse__toggle",on:{"click":function($event){return _vm.toggleCollapse('information')}}},[_vm._v(" "+_vm._s(_vm.$t('nav_information'))+" ")]),_c('div',{staticClass:"collapse__content",attrs:{"id":"collapse__content__information"}},[_c('div',{staticClass:"collapse__item",on:{"click":function($event){_vm.openExternalLink(_vm.appInfo.about_app, _vm.$t('nav_about_app'))}}},[_vm._v(" "+_vm._s(_vm.$t('nav_about_app'))+" ")]),_c('div',{staticClass:"collapse__item",on:{"click":_vm.openLibrary}},[_vm._v(" "+_vm._s(_vm.$t('nav_library'))+" ")]),_c('div',{staticClass:"collapse__item",on:{"click":function($event){_vm.openExternalLink(_vm.appInfo.privacy_policy, _vm.$t('nav_privacy_policy'))}}},[_vm._v(" "+_vm._s(_vm.$t('nav_privacy_policy'))+" ")]),_c('div',{staticClass:"collapse__item",on:{"click":function($event){_vm.openExternalLink(_vm.appInfo.term_of_service, _vm.$t('nav_term_of_service'))}}},[_vm._v(" "+_vm._s(_vm.$t('nav_term_of_service'))+" ")]),_c('div',{staticClass:"collapse__item",on:{"click":function($event){_vm.openExternalLink(_vm.appInfo.operate_company, _vm.$t('nav_company_info'))}}},[_vm._v(" "+_vm._s(_vm.$t('nav_company_info'))+" ")]),_c('router-link',{attrs:{"to":{name: _vm.Page.UserDeactivation},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('div',{staticClass:"collapse__item",on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t('nav_deactivate'))+" ")])]}}])})],1)])])]),_c('div',{staticClass:"page-footer"},[(_vm.appConfig.version)?_c('div',{staticClass:"my-menu__app-version"},[_vm._v(" "+_vm._s(_vm.$t('common_app_version', {version: _vm.appConfig.version}))+" ")]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-menu__main-nav__img"},[_c('img',{attrs:{"src":"/images/icons/icon-bell-black-outline.svg","height":"22.31px","alt":"Person icon"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-menu__main-nav__img"},[_c('img',{attrs:{"src":"/images/icons/icon-notebook.svg","height":"22.31px","alt":"Column icon"}})])}]

export { render, staticRenderFns }