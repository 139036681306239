export const USER_TOKEN = 'USER_TOKEN'

export const SELECTED_BIRTHDAY = 'SELECTED_BIRTHDAY'

export const SELECTED_PROPERTY = 'SELECTED_PROPERTY'

export const SELECTED_YEAR_MONTH = 'SELECTED_YEAR_MONTH'

export const CONFIRMED_CHANGE_EMAIL = 'CONFIRMED_CHANGE_EMAIL'

export const CURRENT_TAX_SESSION = 'CURRENT_TAX_SESSION'

export const CONFIRMED_TAX_SESSION = 'CONFIRMED_TAX_SESSION'

export const CONFIRMED_LOGOUT = 'CONFIRMED_LOGOUT'

export const DEVICE_CONFIG = 'DEVICE_CONFIG'

export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'

export const KEYBOARD = 'KEYBOARD'
