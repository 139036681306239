// region only used in mobile app
export const APP_HOME = 'APP_HOME'

export const APP_HOME_TOP_NOTIFICATION = 'APP_HOME_TOP_NOTIFICATION'

export const APP_HOME_INCOME_EXPENDITURE_SIMULATION = 'APP_HOME_INCOME_EXPENDITURE_SIMULATION'

export const APP_HOME_FINANCE_UPDATE = 'APP_HOME_FINANCE_UPDATE'

export const APP_HOME_CASHFLOW = 'APP_HOME_CASHFLOW'

export const APP_HOME_SIMULATION_TOOL = 'APP_HOME_SIMULATION_TOOL'

export const APP_HOME_TAX = 'APP_HOME_TAX'

export const APP_HOME_COLUMN = 'APP_HOME_COLUMN'

export const APP_TAB_FINANCE = 'APP_TAB_FINANCE'

export const APP_TAB_PROPERTY = 'APP_TAB_PROPERTY'

export const APP_TAB_MY_MENU = 'APP_TAB_MY_MENU'
// endregion only used in mobile app

// region use in web
export const WEB_TAX_TRUSTED = 'WEB_TAX_TRUSTED'

export const WEB_TAX_NOT_TRUSTED = 'WEB_TAX_NOT_TRUSTED'
// endregion use in web
