<template>
  <div class="my-menu">
    <div class="page-body">
      <div class="my-menu__user-name">
        {{ currentUserName }}
      </div>
      <div class="my-menu__main-nav">
        <div
          class="my-menu__main-nav__notification"
          :class="{'my-menu__main-nav__notification--unread': notification.unreadCount}"
          @click="openNotificationList"
        >
          <div class="my-menu__main-nav__img">
            <img
              src="/images/icons/icon-bell-black-outline.svg"
              height="22.31px"
              alt="Person icon"
            >
          </div>
          <div class="my-menu__main-nav__text">
            {{ $t('nav_notice') }}
          </div>
        </div>
        <div
          class="my-menu__main-nav__column"
          @click="openColumnPage(appInfo.column)"
        >
          <div class="my-menu__main-nav__img">
            <img
              src="/images/icons/icon-notebook.svg"
              height="22.31px"
              alt="Column icon"
            >
          </div>
          <div class="my-menu__main-nav__text">
            {{ $t('nav_column') }}
          </div>
        </div>
        <router-link
          v-slot="{ navigate }"
          :to="{name: Page.UserChangeInfo}"
          custom
        >
          <div
            class="my-menu__main-nav__user-info"
            @click="navigate"
          >
            <div class="my-menu__main-nav__img">
              <img
                src="/images/icons/icon-person.svg"
                height="22.31px"
                alt="Person icon"
              >
            </div>
            <div class="my-menu__main-nav__text">
              {{ $t('nav_user_info') }}
            </div>
          </div>
        </router-link>
      </div>
      <div class="my-menu__menu">
        <div
          class="collapse"
          :class="{collapsed: !menuOpenStatus.support}"
        >
          <div
            class="collapse__toggle"
            @click="toggleCollapse('support')"
          >
            {{ $t('nav_support') }}
          </div>
          <div
            id="collapse__content__support"
            class="collapse__content"
          >
            <div
              class="collapse__item"
              @click="openExternalLink(appInfo.faq, $t('nav_faq'))"
            >
              {{ $t('nav_faq') }}
            </div>
            <div
              class="collapse__item"
              @click="openExternalLink(appInfo.contact_us, $t('nav_contact_us'))"
            >
              {{ $t('nav_contact_us') }}
            </div>
            <div
              class="collapse__item"
              @click="openExternalLink(appInfo.info_change_request, $t('nav_info_change_request'))"
            >
              {{ $t('nav_info_change_request') }}
            </div>
          </div>
        </div>
        <div
          class="collapse"
          :class="{collapsed: !menuOpenStatus.config}"
        >
          <div
            class="collapse__toggle"
            @click="toggleCollapse('config')"
          >
            {{ $t('nav_config') }}
          </div>
          <div
            id="collapse__content__config"
            class="collapse__content"
          >
            <router-link
              v-slot="{ navigate }"
              :to="{name: Page.ConfigPushNotification}"
              custom
            >
              <div
                class="collapse__item"
                @click="navigate"
              >
                {{ $t('nav_config_push_notification') }}
              </div>
            </router-link>
            <div
              v-if="userConfig.canChangeEmail"
              class="collapse__item"
              @click="confirmChangeEmail"
            >
              {{ $t('nav_change_email') }}
            </div>
            <router-link
              v-if="userConfig.canChangePassword"
              v-slot="{ navigate }"
              :to="{name: Page.ChangePassword}"
              custom
            >
              <div
                class="collapse__item"
                @click="navigate"
              >
                {{ $t('nav_change_password') }}
              </div>
            </router-link>
            <router-link
              v-slot="{ navigate }"
              :to="{name: Page.ConfigSecurity}"
              custom
            >
              <div
                class="collapse__item"
                @click="navigate"
              >
                {{ $t('nav_config_security') }}
              </div>
            </router-link>
          </div>
        </div>
        <div
          class="collapse"
          :class="{collapsed: !menuOpenStatus.information}"
        >
          <div
            class="collapse__toggle"
            @click="toggleCollapse('information')"
          >
            {{ $t('nav_information') }}
          </div>
          <div
            id="collapse__content__information"
            class="collapse__content"
          >
            <div
              class="collapse__item"
              @click="openExternalLink(appInfo.about_app, $t('nav_about_app'))"
            >
              {{ $t('nav_about_app') }}
            </div>
            <div
              class="collapse__item"
              @click="openLibrary"
            >
              {{ $t('nav_library') }}
            </div>
            <div
              class="collapse__item"
              @click="openExternalLink(appInfo.privacy_policy, $t('nav_privacy_policy'))"
            >
              {{ $t('nav_privacy_policy') }}
            </div>
            <div
              class="collapse__item"
              @click="openExternalLink(appInfo.term_of_service, $t('nav_term_of_service'))"
            >
              {{ $t('nav_term_of_service') }}
            </div>
            <div
              class="collapse__item"
              @click="openExternalLink(appInfo.operate_company, $t('nav_company_info'))"
            >
              {{ $t('nav_company_info') }}
            </div>
            <router-link
              v-slot="{ navigate }"
              :to="{name: Page.UserDeactivation}"
              custom
            >
              <div
                class="collapse__item"
                @click="navigate"
              >
                {{ $t('nav_deactivate') }}
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="page-footer">
      <div
        v-if="appConfig.version"
        class="my-menu__app-version"
      >
        {{ $t('common_app_version', {version: appConfig.version}) }}
      </div>
    </div>
  </div>
</template>

<script>
import WebviewMixin from '@/mixins/webview.mixin'
import { CONFIRM_CHANGE_EMAIL, OPEN_LICENSES, OPEN_NOTIFICATION_LIST, OPEN_WEBVIEW } from '@/enums/mobile-event.enum'
import * as UserRegisterType from '@/enums/user-register-type.enum'
import {addEventListener, openExternalLink, removeEventListener, triggerMobileAction} from '@/utils/webview.util'
import {CONFIRMED_CHANGE_EMAIL, DEVICE_CONFIG, USER_TOKEN} from '@/enums/web-event.enum'
import {mapGetters} from 'vuex'
import {NotificationService} from '@/services/notification.service'

export default {
  name: 'UserMyMenu',
  mixins: [WebviewMixin],
  data () {
    return {
      notification: {
        unreadCount: 0,
        recallTimeout: null
      }
    }
  },
  computed: {
    userConfig () {
      return {
        // TODO: Add user type enum
        canChangePassword: this.currentUser.canChangePassword,
        canChangeEmail: this.currentUser.canChangeEmail,
      }
    },
    ...mapGetters({
      hasToken: 'auth/hasToken',
      appInfo: 'config/appInfo',
      appConfig: 'config/appConfig',
      currentUser: 'auth/currentUser',
      menuOpenStatus: 'menu/openStatus',
      hasPlatform: 'config/hasPlatform',
    }),
    currentUserName () {
      if (this.currentUser.fullname) {
        return `${this.currentUser.fullname}${this.$t('common_sama')}`
      }
      return '-'
    },
  },
  watch: {
    currentUser () {
      Object.keys(this.menuOpenStatus).forEach((groupName) => {
        let el = document.getElementById(`collapse__content__${groupName}`)
        if (el && el.style.maxHeight) {
          this.$nextTick(() => {
            el.style.maxHeight = `${el.scrollHeight}px`
          })
        }
      })
    }
  },
  created () {
    if (this.hasToken) {
      this.initData()
    } else {
      addEventListener(USER_TOKEN, this.initData)
    }
    this.$store.dispatch('config/getAppGeneralInfo')
  },
  mounted () {
    Object.keys(this.menuOpenStatus).forEach((groupName) => {
      if (!this.menuOpenStatus[groupName]) {
        return
      }

      let el = document.getElementById(`collapse__content__${groupName}`)
      if (el) {
        if (el.style.maxHeight) {
          el.style.maxHeight = null
        } else {
          el.style.maxHeight = `${el.scrollHeight}px`
        }
      }
    })
  },
  beforeDestroy () {
    removeEventListener(CONFIRMED_CHANGE_EMAIL, this.redirectToChangeEmail)
    removeEventListener(DEVICE_CONFIG, this.updateAppConfig)
    removeEventListener(USER_TOKEN, this.initData)
    clearTimeout(this.recallTimeout)
  },
  methods: {
    initData (e) {
      if (e?.detail?.token || this.hasToken) {
        this.$store.dispatch('auth/getCurrentUserInfo')
        if (!this.notification.recallTimeout) {
          this.getNumberOfUnreadMessages()
        }
      }
    },
    registerEventListeners () {
      addEventListener(USER_TOKEN, this.onUserTokenGiven)
      addEventListener(DEVICE_CONFIG, this.updateAppConfig)
      addEventListener(CONFIRMED_CHANGE_EMAIL, this.redirectToChangeEmail)

      this.notifyMobilePageReady()
    },
    toggleCollapse (groupName) {
      let el = document.getElementById(`collapse__content__${groupName}`)
      if (el) {
        if (el.style.maxHeight) {
          el.style.maxHeight = null
        } else {
          el.style.maxHeight = `${el.scrollHeight}px`
        }
      }
      this.$store.commit('menu/toggleMenu', {
        groupName,
        isOpen: !this.menuOpenStatus[groupName]
      })
    },
    confirmChangeEmail () {
      if (this.currentUser.type === UserRegisterType.SNS) {
        this.$router.push({name: this.Page.ChangeEmailEmail})
      } else {
        triggerMobileAction(CONFIRM_CHANGE_EMAIL, {})
      }
    },
    redirectToChangeEmail () {
      this.$router.push({name: this.Page.ChangeEmail})
    },
    redirectTo (url) {
      window.location.href = url
    },
    async getNumberOfUnreadMessages () {
      try {
        this.notification.unreadCount = await NotificationService.getNumberOfUnreadMessages()
      } catch (e) {
        // Do nothing here
      } finally {
        this.notification.recallTimeout = setTimeout(this.getNumberOfUnreadMessages, 30000)
      }
    },
    openNotificationList () {
      if (this.hasPlatform) {
        triggerMobileAction(OPEN_NOTIFICATION_LIST, {})
      } else {
        this.$router.push({name: this.Page.Notification})
      }
    },
    openColumnPage (url) {
      if (this.hasPlatform) {
        triggerMobileAction(OPEN_WEBVIEW, {
          url
        })
      } else {
        window.location.href = url
      }
    },
    openLibrary () {
      triggerMobileAction(OPEN_LICENSES)
    },
    openExternalLink (url, title) {
      if (this.hasPlatform) {
        openExternalLink(url, title)
      } else {
        window.location.href = url
      }
    },
  }
}
</script>

<style
  scoped
  lang="scss"
>
.my-menu {
  font-size: 14px;

  .page-body {
    margin: 0 -10px;
    padding-top: 27px;
    width: calc(100% + 20px);
  }
  &__user-name {
    font-size: 16px;
    margin-bottom: 18px;
    text-align: center;
    font-weight: bold;
  }
  &__main-nav {
    padding: 0 5px 5px;
    display: flex;
    border-bottom: 1px solid $border-dark-grey-3;
    font-size: 11px;
    & > div {
      width: calc((100% - 40px) / 3);
      margin: 0 5px;
      text-align: center;
      padding: 20px 0 16px;
      cursor: pointer;
    }
    &__img {
      margin-bottom: 7px;
      display: inline-block;
      position: relative;
    }
    &__notification {
      &--unread .my-menu__main-nav__img::after {
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #FD5621;
        display: block;
        position: absolute;
        right: 2px;
        top: 2px;
      }
    }
  }
  &__menu {
    //margin-top: 50px;
  }

  .collapse {
    &__toggle, &__item {
      user-select: none;
      cursor: pointer;
      border-bottom: 1px solid $border-dark-grey-3;
    }
    &__toggle {
      font-size: 16px;
      padding: 20px;
      position: relative;
      &::after {
        content: '';
        width: 29px;
        height: 29px;
        position: absolute;
        right: 20px;
        top: 12px;
        background-image: url("/images/icons/icon-arrow-top.svg");
        transition: transform 0.2s ease;
        transform: rotate(0deg);
      }
    }
    &.collapsed {
      .collapse__toggle::after {
        transform: rotate(180deg);
      }
    }
    &__item {
      padding: 20px 40px;
      background-color: #F8F8F8;
    }
    &__content {
      overflow: hidden;
      transition: max-height 0.2s ease-out;
      max-height: 0;
    }
  }
  &__app-version {
    margin-top: 70px;
    margin-bottom: 70px;
    text-align: center;
    font-size: 10px;
  }
}
</style>
